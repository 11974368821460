.bottomPage {
  width: 100%;
  background: #161B26;
  overflow: hidden;
  height: 350px;
}
.bottomPage .shell {
  width: 1440px;
  margin: auto;
  overflow: hidden;
}
.bottomPage .shell .shell_1 {
  margin-top: 60px;
  float: left;
}
.bottomPage .shell .shell_1 img {
  width: 61px;
  height: 31px;
  margin-bottom: 12px;
}
.bottomPage .shell .shell_1 p {
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
}
.bottomPage .shell .shell_2,
.bottomPage .shell .shell_3,
.bottomPage .shell .shell_4 {
  float: right;
  width: 100px;
  margin-top: 64px;
  margin-left: 44px;
}
.bottomPage .shell .shell_2 img,
.bottomPage .shell .shell_3 img,
.bottomPage .shell .shell_4 img {
  width: 68px;
  height: 68px;
  display: block;
  margin: auto;
  margin-bottom: 14px;
}
.bottomPage .shell .shell_2 .p_1,
.bottomPage .shell .shell_3 .p_1,
.bottomPage .shell .shell_4 .p_1 {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin-bottom: 6px;
}
.bottomPage .shell .shell_2 .p_2,
.bottomPage .shell .shell_3 .p_2,
.bottomPage .shell .shell_4 .p_2 {
  font-size: 12px;
  color: #fff;
  text-align: center;
}
@media (max-width: 1500px) {
  .bottomPage .shell {
    width: 1200px;
  }
}
.loginFoot {
  display: flex;
  color: #c0c4cc85;
  justify-content: center;
  padding-top: 10px;
  line-height: 32px !important;
}
.loginFoot span {
  font-size: 14px;
  font-family: PingFang SC;
}
.loginFoot .loginpadding span {
  color: #c0c4cc85;
  padding-left: 5px;
}
.loginFoot .loginpadding .el-link.el-link--default:hover {
  color: #fff;
}
