
























































































.bottomPage {
  width: 100%;
  background: #161B26;
  overflow: hidden;
  height: 350px;
  .shell {
    width: 1440px;
    margin: auto;
    overflow: hidden;
    .shell_1 {
      margin-top: 60px;
      float: left;
      img {
        width: 61px;
        height: 31px;
        margin-bottom: 12px;
      }
      p {
        color: #ffffff;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .shell_2,
    .shell_3,
    .shell_4 {
      float: right;
      width: 100px;
      margin-top: 64px;
      margin-left: 44px;
      img {
        width: 68px;
        height: 68px;
        display: block;
        margin: auto;
        margin-bottom: 14px;
      }
      .p_1 {
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        text-align: center;
        margin-bottom: 6px;
      }
      .p_2 {
        font-size: 12px;
        color: #fff;
        text-align: center;
      }
    }
  }
}
@media (max-width: 1500px) {
  .bottomPage .shell{
    width: 1200px;
  }
}
.loginFoot {
  display: flex;
  color: #c0c4cc85;
  justify-content: center;
  padding-top: 10px;
  line-height: 32px !important;
  span {
    font-size: 14px;
    font-family: PingFang SC;
  }
  .loginpadding {
    // padding: 20px 0;
    span {
      color: #c0c4cc85;
      padding-left: 5px;
    }

    .el-link.el-link--default:hover {
      color: #fff;
    }
  }
}
