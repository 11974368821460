.courseCenter {
  margin-top: 62px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.courseCenter::-webkit-scrollbar {
  width: 0 !important;
}
.courseStudy {
  width: 1168px;
  margin: 0 auto;
}
.courseStudy /deep/ .el-breadcrumb {
  margin: 32px 0 22px 0;
  font-size: 16px;
  color: #666;
}
.section--bottom {
  width: 100%;
  min-height: 72px;
  padding: 15px 23px 15px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section--bottom .courseInformation h4 {
  color: #fff;
}
.section--bottom .courseInformation h4 span {
  color: #ff4f23;
  margin-top: 0;
  margin-left: 15px;
  opacity: 0.87;
}
.section--bottom .courseInformation span {
  color: #fff;
  opacity: 0.5;
  margin-top: 15px;
}
.section--bottom .courseInformation span span {
  opacity: 1;
  margin-top: 0;
}
.section--top {
  display: flex;
  width: 100%;
  font-size: 0;
}
.section--top .section--video {
  width: 868px;
  position: relative;
}
.section--top .section--video #J_prismPlayer {
  position: absolute;
}
.section--top .section--video #J_prismPlayer /deep/ .prism-cover {
  background-size: 100% 100% !important;
}
.section--top .task-list-wrapper {
  display: inline-block;
  vertical-align: top;
  height: 488px;
  width: 300px;
  background-color: #222;
  font-size: 12px;
  position: relative;
  padding: 20px 0;
  overflow: auto;
}
.section--top .task-list-wrapper .el-tree {
  height: 100%;
  background: #222;
  color: #fff;
}
.section--top .task-list-wrapper .el-tree /deep/ .el-tree-node__content {
  height: 59px;
}
.section--top .task-list-wrapper .el-tree /deep/ .el-tree-node__content:hover {
  background: #222;
}
.section--top .task-list-wrapper .el-tree /deep/ .el-tree-node:focus > .el-tree-node__content {
  background-color: #222;
}
.section--top .task-list-wrapper /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background: #222;
  color: #2878ff;
}
.section--top .task-list-wrapper .ellipsis {
  font-size: 0.9rem;
}
.section--top .task-list-wrapper .texthidden {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.courseContextBox {
  background: #f9f9f9;
  padding: 30px 0;
}
.courseContextDetail {
  display: flex;
  width: 1168px;
  margin: 0 auto;
  justify-content: space-between;
}
.courseContextDetail .courseContext {
  width: 811px;
  background: #fff;
}
.courseContextDetail .courseContext h4 {
  margin-bottom: 20px;
}
.courseContextDetail .courseContext .coursebreak {
  line-height: 30px;
  word-break: break-all;
}
.courseContextDetail .courseContext .coursebreak /deep/ img {
  display: block !important;
  margin: auto !important;
  width: 100% !important;
}
.courseContextDetail > div {
  padding: 20px;
}
.trySee {
  width: 46px;
  height: 21px;
  background: #2878ff;
  border-radius: 11px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactTeacherPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.contactTeacherPopup p {
  font-size: 18px;
}
.courseCenter .popUp /deep/ .el-dialog__wrapper {
  z-index: 9999 !important;
}
.courseCenter .popUp /deep/ .el-dialog {
  background: transparent;
}
.courseCenter .popUp /deep/ .el-dialog__header {
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px 20px 10px;
}
.courseCenter .popUp /deep/ .el-dialog__header .el-dialog__title {
  color: #333;
  font-size: 20px;
}
.courseCenter .popUp /deep/ .el-dialog__header .el-dialog__headerbtn {
  top: 38px;
}
.courseCenter .popUp /deep/ .el-dialog__header .el-dialog__headerbtn .el-dialog__close {
  color: #333;
}
.courseCenter .popUp /deep/ .el-dialog__header .el-dialog__headerbtn:focus .el-dialog__close,
.courseCenter .popUp /deep/ .el-dialog__header .el-dialog__headerbtn:hover .el-dialog__close {
  color: #333;
}
.courseCenter .popUp /deep/ .el-dialog__body {
  background: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0 30px;
}
.courseCenter .popUp .el-divider--horizontal {
  margin: 0 !important;
}
.courserecommend {
  width: 297px;
  height: 100%;
  display: flex;
}
.courseBox {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.courseBox > div {
  border: 1px solid #edf4ff;
  border-radius: 0.3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-bottom: 20px;
}
.courseBox > div:hover {
  cursor: pointer;
  box-shadow: 0px 0px 23px 0px rgba(40, 120, 255, 0.1);
}
.courseBox > div .courseDetail {
  padding: 1.1rem 0.75rem 0.55rem;
}
.courseBox > div .courseDetail span {
  color: #666;
}
.courseBox > div .courseDetailBtn {
  padding: 0 0.75rem 0.55rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.courseBox > div .courseDetailBtn span {
  color: #999;
}
.courseBox > div .courseDetailBtn button {
  background: #fff;
  color: #5393ff;
  border-color: #5393ff;
}
.courseBox img {
  width: 100%;
  border-radius: 0.3rem 0.3rem 0 0;
  height: 158px;
}
.courseBox p {
  text-align: left;
  color: #333;
  font-size: 1rem;
  padding: 0.55rem 0.75rem 0 0.75rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.courseBox span {
  font-size: 0.8rem;
}
