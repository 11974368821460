





























































































































































































































































































































































































































































































































































































































































.el-tree-node {
  white-space: none;
}
.vip_limit_content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.vip_limit_content .title {
  font-size: 18px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  width: 100%;
}

.vip_limit_button_box {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.vip_limit_btn {
  display: inline-block;
  min-width: 100px;
  position: relative;
  background: #f60;
  padding: 0 35px;
  margin: 0px 5px 20px 5px;
  border-radius: 38px;
  font-size: 18px;
  line-height: 38px;
  color: #623a0c;
  text-align: center;
  background-image: linear-gradient(
    -135deg,
    #fbe8a8 0%,
    #f8e7ac 15%,
    #e2c078 100%
  );
  cursor: pointer;
}

.vip_limit_close {
  text-align: center;
}
.vip_limit_close span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 36px;
  background: rgba(165, 165, 165, 0.54);
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
}
