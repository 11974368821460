




























































































































































































































































































































































































































































































































































































































































































































.courseCenter {
  margin-top: 62px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0 !important }
}
.courseStudy {
  width: 1168px;
  margin: 0 auto;
  /deep/ .el-breadcrumb {
    margin: 32px 0 22px 0;
    font-size: 16px;
    color: #666;
  }
}
.section--bottom {
  width: 100%;
  min-height: 72px;
  padding: 15px 23px 15px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .courseInformation {
    h4 {
      color: #fff;
      span {
        color: #ff4f23;
        margin-top: 0;
        margin-left: 15px;
        opacity: 0.87;
      }
    }
    span {
      color: #fff;
      opacity: 0.5;
      margin-top: 15px;
      span {
        opacity: 1;
        margin-top: 0;
      }
    }
  }
}
.section--top {
  display: flex;
  width: 100%;
  font-size: 0;
  .section--video {
    width: 868px;
    position: relative;
    #J_prismPlayer {
      position: absolute;
      /deep/.prism-cover {
        background-size: 100% 100% !important;
      }
    }
  }
  .task-list-wrapper {
    display: inline-block;
    vertical-align: top;
    height: 488px;
    width: 300px;
    background-color: #222;
    font-size: 12px;
    position: relative;
    padding: 20px 0;
    overflow: auto;
    .el-tree {
      height: 100%;
      background: #222;
      color: #fff;
      //   opacity: 0.5;

      /deep/.el-tree-node__content {
        height: 59px;
      }
      /deep/.el-tree-node__content:hover {
        background: #222;
      }
      /deep/.el-tree-node:focus > .el-tree-node__content {
        background-color: #222;
      }
      //   /deep/.el-tree-node {
      //     margin: 10px 0;
      //   }
    }
    /deep/
      .el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content {
      background: #222;
      color: #2878ff;
    }
    .ellipsis {
      font-size: 0.9rem;
    }
    .texthidden {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.courseContextBox {
  background: #f9f9f9;
  padding: 30px 0;
}
.courseContextDetail {
  display: flex;
  width: 1168px;
  margin: 0 auto;
  justify-content: space-between;
  .courseContext {
    width: 811px;
    background: #fff;
    h4 {
      margin-bottom: 20px;
    }
    .coursebreak {
      line-height: 30px;
      word-break: break-all;
      /deep/ img {
        display: block !important;
        margin: auto !important;
        width: 100% !important;
      }
    }
  }
  > div {
    padding: 20px;
  }
}
.trySee {
  width: 46px;
  height: 21px;
  background: rgb(40, 120, 255);
  border-radius: 11px;
  color: rgb(255, 255, 255);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactTeacherPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  p {
    font-size: 18px;
  }
}
.courseCenter {
  .popUp{
    /deep/.el-dialog__wrapper {
      z-index: 9999 !important;
    }
    /deep/.el-dialog {
      background: transparent;
    }
    /deep/.el-dialog__header {
      background: #fff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 20px 20px 10px;
      .el-dialog__title {
        color: #333;
        font-size: 20px;
      }
      .el-dialog__headerbtn {
        top: 38px;
        .el-dialog__close {
          color: #333;
        }
      }
      .el-dialog__headerbtn:focus .el-dialog__close,
      .el-dialog__headerbtn:hover .el-dialog__close {
        color: #333;
      }
    }
    /deep/.el-dialog__body {
      background: #fff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 0 30px;
    }
    .el-divider--horizontal {
      margin: 0 !important;
    }
  }
}
.courserecommend {
  width: 297px;
  height: 100%;
  display: flex;
}
.courseBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  > div {
    border: 1px solid #edf4ff;
    border-radius: 0.3rem;
    // width: calc((100% - 60px) / 4);
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-bottom: 20px;
    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 23px 0px rgba(40, 120, 255, 0.1);
    }
    .courseDetail {
      padding: 1.1rem 0.75rem 0.55rem;
      span {
        color: #666;
      }
    }
    .courseDetailBtn {
      padding: 0 0.75rem 0.55rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #999;
      }
      button {
        background: #fff;
        color: #5393ff;
        border-color: #5393ff;
      }
    }
  }
  img {
    width: 100%;
    border-radius: 0.3rem 0.3rem 0 0;
    height: 158px;
  }
  p {
    text-align: left;
    color: #333;
    font-size: 1rem;
    padding: 0.55rem 0.75rem 0 0.75rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  span {
    font-size: 0.8rem;
  }
}
